export default function() {
    const filters = document.querySelector('.filters');
    const filtersToggle = document.querySelector('.filters__toggle');

    const filtersOptionsToggles = document.querySelectorAll('.filters__fieldset-toggle');
    const filtersOptions = document.querySelectorAll('.filters__option-set');

    if (filters) {
        filtersToggle.addEventListener('click', function() {
            let isOpen = $(this).attr('aria-expanded');

            if (isOpen === 'false') {
                $(this).attr('aria-expanded', 'true');

                $(filters).slideDown(1000);
            } else {
                $(this).attr('aria-expanded', 'false');
                $(filters).slideUp(1000);
            }
        });

        filtersOptionsToggles.forEach((el, i) => {
            el.addEventListener('click', function() {
                let isOpen = $(el).attr('aria-expanded');

                if (isOpen === 'false') {
                    $(el).attr('aria-expanded', 'true');
                    $(filtersOptions[i]).addClass('open');
                } else {
                    $(el).attr('aria-expanded', 'false');
                    $(filtersOptions[i]).removeClass('open');
                }
            });
        });
    }
}