import Masonry from 'masonry-layout';
import imagesLoaded from 'imagesloaded';

export default function() {
    if (document.querySelector('.masonry-container')) {
        const imgLoad = imagesLoaded('.masonry-container');

        const masonry = new Masonry('.masonry-container', {
            itemSelector: '.masonry-grid__item',
            columnWidth: '.masonry-grid__sizer',
            percentPosition: true,
            horizontalOrder: true,
        })
    
        imgLoad.on('progress', () => {
            masonry.layout();
        })
    }
}
