export default function() {
    initMobileNavigationToggle();
    initSubnavToggles();
}

function initMobileNavigationToggle() {
    let toggle = document.querySelector('.navigation__toggle');
  
    toggle.addEventListener('click', handleMobileToggleClick);
}

function initSubnavToggles() {
    let toggles = document.querySelectorAll('.navigation__level-one-toggle');
  
    toggles.forEach((el) => el.addEventListener('click', handleSubnavToggleClick))
}

function handleMobileToggleClick() {
    let isOpen = this.getAttribute('aria-expanded');
    let lower = document.querySelector('.navigation__links');
  
    if (isOpen === 'false') {
        $(lower).addClass('open');
        $(lower).slideDown();
        this.setAttribute('aria-expanded', 'true');
        this.setAttribute('aria-label', 'Close primary navigation menu');
    } else {
        $(lower).removeClass('open');
        $(lower).slideUp();
        this.setAttribute('aria-expanded', 'false');
        this.setAttribute('aria-label', 'Open primary navigation menu');
    }
}

function handleSubnavToggleClick() {
    let isOpen = this.getAttribute('aria-expanded');
    let controls = this.getAttribute('aria-controls');

    let allItems = document.querySelectorAll('.navigation__level-one-item');
    let lower = document.getElementById(controls);
    
    allItems.forEach((el) => {
        if (el.querySelector('.navigation__level-two')) {
            el.querySelector('.navigation__level-two').classList.remove('open');
            el.querySelector('.navigation__level-one-toggle').setAttribute('aria-expanded', 'false');
        }
    });

    if (isOpen === 'false') {
        $(lower).addClass('open');
        this.setAttribute('aria-expanded', 'true');
    } else {
        $(lower).removeClass('open');
        this.setAttribute('aria-expanded', 'false');
    }
}