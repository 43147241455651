import Cookies from 'js-cookie'

export default function() {
    $(document).ready(function() {
        $('.cookies-banner__options .btn').on('click', function (e) {
            e.preventDefault();
            Cookies.set('cookie_preferences_saved', 'true', {domain: window.COOKIE_CONSENT_DOMAIN, expires: 365 });
            Cookies.set('analytics_cookie_consent', 'allow', {domain: window.COOKIE_CONSENT_DOMAIN, expires: 365 });
            Cookies.set('advanced_tracking_cookie_consent', 'allow', {domain: window.COOKIE_CONSENT_DOMAIN, expires: 365 });
            window.dataLayer.push({'event': 'analytics_cookie_consent'});
            window.dataLayer.push({'event': 'advanced_tracking_cookie_consent'});
            window.dataLayer.push({'event': 'cookie_preferences_saved'});
            $('.cookies-banner').fadeOut();
            if($('#id_analytics_cookie_consent').length) {
                $('input:radio[name=analytics_cookie_consent]').val(['allow']);
                $('input:radio[name=advanced_tracking_cookie_consent]').val(['allow']);
            }
        });
    })
}
