export default function() {
    var $feedback_form = document.getElementById('feedback_form');

    if ($feedback_form) {
        $feedback_form.onsubmit = function(e){
            e.preventDefault();
            submitFeedback();
        };
        var $yes = document.getElementById('id_option_0');
        var $no = document.getElementById('id_option_1');

        $yes.addEventListener('change', function(e){
            submitFeedback();
        });

        $no.addEventListener('change', function(e){
            var $stage1 = document.getElementById('stage1');
            var $stage2 = document.getElementById('stage2');
            $stage1.classList.add('hidden');
            $stage2.classList.remove('hidden');
        });
    }

    var getCookie = function(name) {
        var cookies = document.cookie.split(';');
        for(var i=0 ; i < cookies.length ; ++i) {
            var pair = cookies[i].trim().split('=');
            if(pair[0] == name)
                return pair[1];
        }
        return null;
    };

    function feedbackSuccess(response) {
        var $submission_id_field = document.getElementById('id_submission_id');
        $submission_id_field.value = response['submission_id']
        if(response['finished'] === true){
            var $stage1 = document.getElementById('stage1');
            var $stage2 = document.getElementById('stage2');
            var $stage3 = document.getElementById('stage3');
            $stage1.classList.add('hidden');
            $stage2.classList.add('hidden');
            $stage3.classList.remove('hidden');
        }
    }

    function submitFeedback(data) {

        var xhr = new XMLHttpRequest();
        // var csrf_token = document.querySelector("meta[name='csrf-token']").getAttribute("content");
        var csrf_token = getCookie('csrftoken');
        var form_data = new FormData( document.getElementById('feedback_form') );
        xhr.open('POST', $feedback_form.getAttribute('action'), true);
        xhr.setRequestHeader("X-CSRFToken", csrf_token);
        xhr.setRequestHeader('Content-Type', 'application/json');
        var payload = {};
        form_data.forEach(function(value, key){
            payload[key] = value;
        });
        var json = JSON.stringify(payload);

        xhr.onload = function() {
            var jsonr = JSON.parse(xhr.response);
            if (xhr.status == 200 && jsonr.success) {
                feedbackSuccess(jsonr);
            }
            else {
                console.log(jsonr)
            }
        };

        xhr.send(json);
    }
}