import TomSelect from 'tom-select';

TomSelect.define('remove_button', require('tom-select/dist/js/plugins/remove_button.js'));

const postcodeWrapper = document.querySelector('#postcode_wrapper');

const showPostcodeWrapper = function() { 
    postcodeWrapper.classList.remove('hidden');
}

const hidePostcodeWrapper = function() {
    postcodeWrapper.classList.add('hidden');
}

export default function() {
    const selects = document.querySelectorAll('select');

    if (document.querySelector('#id_regions')) {
        let regionSelect = new TomSelect('#id_regions', {
            plugins: {
                remove_button:{
                    title:'Remove this item',
                }
            }
        });

        if (regionSelect.items.length > 0) {
            showPostcodeWrapper();
        };

        regionSelect.on('item_add', showPostcodeWrapper);
        regionSelect.on('item_remove', hidePostcodeWrapper);
    }

    if (selects.length > 0) {
        selects.forEach((el) => {
            if (el.classList.contains('tomselected')) return;
            if (el.closest('.no-tomselect')) return;

            new TomSelect(el, {
                plugins: {
                    remove_button:{
                        title:'Remove this item',
                    }
                }
            });
        });
    }
}