import MicroModal from "micromodal";

var getCookie = function (name) {
  var cookies = document.cookie.split(";");
  for (var i = 0; i < cookies.length; ++i) {
    var pair = cookies[i].trim().split("=");
    if (pair[0] == name) return pair[1];
  }
  return null;
};

function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export default function () {
  if (document.querySelector(".modal")) {
    MicroModal.init();

    if (document.querySelector("#modal--default-open")) {
      MicroModal.show("modal--default-open");
    }
  }

  if (document.querySelector(".modal--school-info")) {
    const modalDismiss = document.querySelector("#modal__dismiss");
    const modalCookie = getCookie("school-data-popover");

    if (modalCookie !== "dismissed") {
      MicroModal.show("modal--school-info");

      modalDismiss.addEventListener("click", function () {
        setCookie("school-data-popover", "dismissed", 365);
      });
    }
  }

  if (document.querySelector(".modal--phase-info")) {
    const modalDismiss = document.querySelector("#modal__dismiss");
    const modalCookie = getCookie("phase-data-popover");

    if (modalCookie !== "dismissed") {
      MicroModal.show("modal--phase-info");

      modalDismiss.addEventListener("click", function () {
        setCookie("phase-data-popover", "dismissed", 365);
      });
    }
  }
}
