/*------------------------------------*\
  #CSS
\*------------------------------------*/

import '../css/main.css';

/*------------------------------------*\
  #COMPONENTS
\*------------------------------------*/

import initNav from './components/navigation';
import initCookies from './components/cookies';
import initFilters from './components/filters';
import initMasonry from './components/masonry';
import initFeedbackForm from './components/feedback';
import initSelects from './components/select';
import initModals from './components/modals';

initNav();
initCookies();
initFilters();
initMasonry();
initFeedbackForm();
initSelects();
initModals();

document.querySelector('html').classList.remove('no-js');
